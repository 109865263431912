import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom'
import Table from '../../../../components/table/Table';
import Modal from '../../../../components/template/Modal';
import EvaluationNewModal from './EvaluationNewModal';
import EvaluationModal from './EvaluationModal';
import Spinner from '../../../../components/template/Spinner';
import ButtonFull from '../../../../components/template/ButtonFull';
import api from '../../../../components/utils/api';

const EvaluationPage = () => {
    const { state } = useLocation();
    const [modalVisibleNew, setModalVisibleNew] = useState(false);
    const [modalVisibleResult, setModalVisibleResult] = useState(false);
    const [evaluations, setEvaluations] = useState([])
    const [resultData, setResultData] = useState({})
    const [isDisabled, setIsDisabled] = useState(false)
    const openModal = () => { setModalVisibleNew(true); }
    const closeModal = () => { setModalVisibleNew(false); }
    const openModalResult = () => { setModalVisibleResult(true); }
    const closeModalResult = () => { setModalVisibleResult(false); }

    const fetchEvaluations = useCallback(() => {
        if (state) {
            api.get(`/api/post-action/merge-rules/${state.selectedMergeRule.id}/evaluations?general_model_id=${state.generalModels.id}`)
                .then(res => setEvaluations([...res.data.evaluations]))
                .catch(err => console.error(err))
        }
    }, [state])

    useEffect(() => {
        fetchEvaluations()
    }, [fetchEvaluations])

    const handleTriggerEvaluation = (threshold_id, evaluation_id) => {
        setIsDisabled(true)
        api.put(`/api/post-action/thresholds/${threshold_id}/evaluations/${evaluation_id}`)
            .then(() => {
                setEvaluations((prevEvaluations) =>
                    prevEvaluations.map((evaluation) =>
                        evaluation.evaluation_id === evaluation_id
                            ? { ...evaluation, status: 1 }
                            : evaluation
                    )
                );
            })
            .catch(error => console.error(error))
    }

    const columns = useMemo(
        () => [
            {
                Header: "Threshold",
                accessor: "threshold_id",
                Cell: ({ row }) => <div>{row.original.threshold.name}</div>
            },
            {
                Header: "Evaluation ID",
                accessor: "evaluation_id",
            },
            {
                Header: "Date",
                accessor: "created_at",
            },
            {
                Header: "Result",
                accessor: "result",
                Cell: ({ row }) =>
                    <>{
                        row.original.status === 2 ?
                            <div className='w-40'>
                                <ButtonFull contents={"Result"} onClick={() => { setResultData(row.original); openModalResult() }} />
                            </div>
                            :
                            row.original.status === 1 ?
                                <div className='w-40 flex font-semibold py-2 px-4 border rounded bg-transparent text-gray-500 border-gray-300'>
                                    <div className='pl-2'><Spinner /></div>
                                    <div className='m-auto cursor-default text-sm'>Evaluating...</div>
                                </div>
                                :
                                <div className='w-40'>
                                    <ButtonFull contents={"Evaluate"} onClick={() => { handleTriggerEvaluation(row.original.threshold.id, row.original.evaluation_id) }} isDisabled={isDisabled} />
                                </div>
                    }</>
            },
        ], []
    )

    return (
        <div>
            <Table data={evaluations} columns={columns} additionalButton={{ contents: "New Evaluation", onClick: () => openModal() }} />
            {modalVisibleNew && <Modal
                visible={modalVisibleNew}
                closable={true}
                maskClosable={true}
                onClose={closeModal}>{
                    <EvaluationNewModal state={state} closeModal={closeModal} />
                }</Modal>}
            {modalVisibleResult && <Modal
                visible={modalVisibleResult}
                closable={true}
                maskClosable={true}
                onClose={closeModalResult}>{
                    <EvaluationModal resultData={resultData} mergeRuleId={state.selectedMergeRule.id} />
                }</Modal>}
        </div>
    )
}

export default EvaluationPage